
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    useAbsolutePosition: {
      type: Boolean,
      default: true,
    },
    overlayOpactity: {
      type: Number,
      default: 0.6,
    },
    overlayBgColor: {
      type: String,
      default: "",
    },
    loadingProgress: {
      type: Number,
      default: 0,
    },
  },
}
