import { defineStore } from "pinia"

type LayoutState = {
  isMiniSidebar: boolean
}
export const useLayoutStore = defineStore({
  id: "layout",
  state: (): LayoutState => ({
    isMiniSidebar: false,
  }),
})
