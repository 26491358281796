
import { GoogleMapType } from "@evercam/shared/types/map"
/*global google*/

export default {
  name: "GoogleMapsWrapper",
  props: {
    markers: {
      type: Array,
      required: true,
    },
    center: {
      type: Object,
      default: () => ({ lat: 0, lng: 0 }),
    },
    zoom: {
      type: Number,
      default: 5,
    },
    mapType: {
      type: String,
      default: GoogleMapType.Terrain,
    },
  },
  data() {
    return {
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      showInfoWindow: false,
      currentMarker: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
      },
      map: null,
    }
  },
  watch: {
    showInfoWindow(val) {
      this.$emit("info-window-visibility-change", val)
    },
    mapType(val) {
      this.map.setMapTypeId(val)
    },
  },
  mounted() {
    this.$refs.gmap?.$mapPromise?.then((map) => {
      const bounds = new google.maps.LatLngBounds()
      for (let loc of this.markers.map((m) => m.position)) {
        if (loc) {
          bounds.extend(loc)
        }
      }
      map.fitBounds(bounds)
      map.setMapTypeId(this.mapType)

      this.map = map
      this.$emit("map-ready", map)
    })
  },
  methods: {
    onMarkerClick(marker, index) {
      this.currentMarker = {
        ...marker,
        isClicked: true,
        isHovered: false,
      }
      this.infoWindowPos = marker.position
      this.showInfoWindow = true
      this.$emit("marker-click", marker, index)
    },
    onMarkerHover(marker) {
      if (marker) {
        // Mouse over
        if (!this.currentMarker?.isClicked) {
          this.currentMarker = {
            ...marker,
            isHovered: true,
            isClicked: false,
          }
          this.infoWindowPos = marker.position
          this.showInfoWindow = true
        }
      } else {
        // Mouse out
        if (this.currentMarker?.isHovered && !this.currentMarker?.isClicked) {
          // Close the info window only if it was opened due to hover
          this.showInfoWindow = false
          this.currentMarker = null
        }
      }
      this.$emit("marker-hover", marker)
    },
    onInfoWindowItemClick(marker) {
      this.$emit("info-window-item-click", marker)
    },
    onInfoWindowItemHover(marker) {
      this.$emit("info-window-item-hover", marker)
    },
    closeInfoWindow() {
      this.showInfoWindow = false
      this.currentMarker = null
    },
    zoomChanged(zoom) {
      this.$emit("zoom-changed", zoom)
    },
  },
}
