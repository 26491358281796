
import Vue, { PropType } from "vue"
import { Camera } from "@evercam/shared/types/camera"
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"

export default Vue.extend({
  components: {
    CameraStatusIcon,
  },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      default: () => ({} as Camera),
    },
    showLink: {
      type: Boolean,
      default: () => true,
    },
    showImage: {
      type: Boolean,
      default: () => true,
    },
    isCamera: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    marker360: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapStores(useAccountStore, useProjectStore, useCameraStore),
    thumbnailUrl(): string {
      return this.isCamera
        ? this.camera?.thumbnailUrl
        : this.marker360?.thumbnailLink
    },
    projectName(): string {
      return this.isCamera
        ? this.camera?.project?.name
        : this.projectStore.selectedProjectName
    },
  },
  methods: {
    openCameraOrPano() {
      this.marker360?.type == "AerialShot" || this.marker360?.type == "_360Path"
        ? this.$emit("on-open-pano", this.marker360)
        : this.$emit("on-open-camera", this.cameraStore.selectedCameraExid)
    },
  },
})
