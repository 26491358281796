
/*global google*/

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    showFov: {
      type: Boolean,
      default: true,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
    hoverColor: {
      type: String,
      default: "white",
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FovCenterIcon: {
        path: "M 11 7 h -5 l -6 10 l -6 -10 h -5 a 3 3 0 0 0 -3 3 v 16 a 3 3 0 0 0 3 3 h 3 v 15 a 3 3 0 0 0 3 3 h 10 a 3 3 0 0 0 3 -3 V 29 h 3 a 3 3 0 0 0 3 -3 V 10 a 3 3 0 0 0 -3 -3 z M 0 6 C -3 6 -6 3 -6 0 C -6 -3 -3 -6 0 -6 C 3 -6 6 -3 6 0 C 6 3 3 6 0 6",
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: "#FFFFFF",
        fillOpacity: 0.9,
        scale: 0.7,
      },
      FovPolygonPoint: {
        path: "M.35-11.8c-6.1824 0-11.2 5.0176-11.2 11.2S-5.8324 10.6.35 10.6S11.55 5.5824 11.55-.6S6.5324-11.8.35-11.8zM.35-23C-12.026-23-22.05-12.976-22.05-.6s10.024 22.4 22.4 22.4S22.75 11.776 22.75-.6S12.726-23 .35-23zM.35 17.32c-9.9008 0-17.92-8.0192-17.92-17.92S-9.5508-18.52.35-18.52S18.27-10.5008 18.27-.6S10.2508 17.32.35 17.32z",
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: "#FFFFFF",
        fillOpacity: 1,
        scale: 0.5,
      },
      camera: undefined,
      forceUpdatePoint: false,
    }
  },
  computed: {
    cameraIcon() {
      if (!this.camera?.isOnline) {
        return "/offline_marker.png"
      } else {
        if (!this.showFov) {
          return "/online_marker.png"
        } else {
          return {
            path: "M -131 -45 h 80 v 35 l 50 -30 v 80 l -50 -30 v 35 h -150 V -45 Z",
            fillOpacity: 0,
            strokeColor: this.cameraColor,
            strokeOpacity: 1.0,
            strokeWeight: 3,
            scale: 0.25,
            rotation: this.camera.heading,
          }
        }
      }
    },
    cameraColor() {
      if (this.hovered) {
        return this.hoverColor
      } else {
        return "#428BCA"
      }
    },
    FOVPoints() {
      //hacck to force update of the FOV
      this.forceUpdatePoint

      let markerLocation = new google.maps.LatLng(
        this.camera.location.lat,
        this.camera.location.lng
      )
      var arc = [
        (this.camera.heading % 360) + 90 - this.camera.fovAngle,
        (this.camera.heading % 360) + 90 + this.camera.fovAngle,
        "red",
      ]
      var point,
        points = Array(),
        angle = arc[0],
        index = 0
      while (angle < arc[1] && index < 180) {
        point = new google.maps.geometry.spherical.computeOffset(
          markerLocation,
          this.camera.fovRadius,
          angle
        )
        points.push(point)
        angle++
        index++
      }
      points.unshift(markerLocation)

      return points
    },
    FOVMidPoint() {
      return this.FOVPoints[
        this.FOVPoints.length % 2 == 1
          ? (this.FOVPoints.length - 1) / 2
          : this.FOVPoints.length / 2
      ]
    },
    FOVLeftPoint() {
      return this.FOVPoints[1]
    },
    FOVRightPoint() {
      return this.FOVPoints[this.FOVPoints.length - 1]
    },
  },
  watch: {
    value(v) {
      this.camera = v
    },
  },
  beforeMount() {
    this.camera = this.value
  },
  methods: {
    CameraDragged(event) {
      this.camera.location = event.latLng.toJSON()
    },
    FOVCenterDragged(event) {
      this.camera.fovRadius =
        google.maps.geometry.spherical.computeDistanceBetween(
          event.latLng,
          new google.maps.LatLng(
            this.camera.location?.lat,
            this.camera.location?.lng
          )
        )
      this.camera.heading =
        (Math.atan2(
          event.latLng.lng() - this.camera.location?.lng,
          event.latLng.lat() - this.camera.location?.lat
        ) *
          180) /
          Math.PI -
        90

      this.forceUpdatePoint = !this.forceUpdatePoint
    },
    FOVEdgeDragged(event) {
      const AB = Math.sqrt(
        Math.pow(this.FOVMidPoint.lat() - event.latLng.lat(), 2) +
          Math.pow(this.camera.location.lng - event.latLng.lng(), 2)
      )
      const BC = Math.sqrt(
        Math.pow(this.camera.location.lat - this.FOVMidPoint.lat(), 2) +
          Math.pow(this.camera.location.lng - this.FOVMidPoint.lng(), 2)
      )
      const AC = Math.sqrt(
        Math.pow(this.FOVMidPoint.lat() - event.latLng.lat(), 2) +
          Math.pow(this.FOVMidPoint.lng() - event.latLng.lng(), 2)
      )
      let fovAngle =
        (Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180) /
        Math.PI

      if (fovAngle > 90) {
        fovAngle = 90
      } else if (fovAngle <= 1 || isNaN(fovAngle)) {
        fovAngle = 1
      }
      this.camera.fovAngle = fovAngle
      this.forceUpdatePoint = !this.forceUpdatePoint
    },
  },
}
